import { Button, Image, Stack, Text } from '@chakra-ui/react';

export default function NotFoundPage() {
  return (
    <Stack w='full' minH='100vh'>
      <Stack
        w='full'
        h='full'
        justifyContent='center'
        alignItems='center'
        px='8'
        py='32'
      >
        <Image
          src='/images/brand-logo.png'
          alt='Procura Business Logo'
          width='240px'
          objectFit='contain'
          objectPosition='center'
        />

        <Text
          fontSize={{ base: '6xl', md: '8xl' }}
          fontWeight='bold'
          color='gray.800'
        >
          404
        </Text>

        <Text
          fontSize={{ base: 'xl', md: '2xl' }}
          fontWeight='medium'
          color='gray.800'
          mb='4'
        >
          Page Not Found
        </Text>

        <Text
          display='block'
          textAlign='center'
          maxW='xl'
          fontSize={{ base: 'sm', md: 'md' }}
          fontWeight='normal'
          color='gray.500'
        >
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </Text>

        <Button as='a' href='/' variant='procura' size='lg' mt='8'>
          Go to Home
        </Button>
      </Stack>
    </Stack>
  );
}

NotFoundPage.getLayout = function getLayout(page) {
  return page;
};
